var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
/* eslint-disable no-unused-vars, @typescript-eslint/no-unused-vars */
const Search = (_a) => {
    var { unique = '' } = _a, props = __rest(_a, ["unique"]);
    return (React.createElement("svg", Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", fill: "none", viewBox: "0 0 24 24", role: "presentation", focusable: "false" }, props),
        React.createElement("path", { fill: "#000", fillRule: "evenodd", d: "M9.207 2.043A8.06 8.06 0 0 0 4.9 3.847a7.86 7.86 0 0 0-2.1 2.682 7.99 7.99 0 0 0 .989 8.511c.286.354.972 1.027 1.311 1.286 1.643 1.255 3.734 1.85 5.732 1.632 1.3-.146 2.545-.6 3.633-1.326l.446-.301 2.714 2.717c1.493 1.494 2.791 2.762 2.883 2.817.459.274 1.079.103 1.359-.373.097-.165.113-.235.113-.492 0-.254-.016-.327-.107-.48-.061-.101-1.302-1.372-2.825-2.895l-2.717-2.714.301-.446a7.98 7.98 0 0 0 .568-7.936 7.86 7.86 0 0 0-2.1-2.682c-1.617-1.353-3.763-2.01-5.893-1.804m1.357 1.996a5.99 5.99 0 0 1 5.107 4.021c.497 1.457.397 3.191-.261 4.52-.86 1.736-2.354 2.898-4.25 3.305-.606.13-1.714.13-2.32 0-1.899-.407-3.389-1.566-4.25-3.305-.658-1.329-.758-3.063-.261-4.52.29-.849.77-1.62 1.402-2.255a5.92 5.92 0 0 1 4.833-1.766", clipRule: "evenodd" })));
};
/* eslint-enable no-unused-vars, @typescript-eslint/no-unused-vars */
export default Search;
